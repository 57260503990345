import React from 'react';
import PropTypes from 'prop-types';

import { DocumentationContainer } from '../Layout';
import { SCOPE_LABELS } from './lib';
import './styles/teaser.styl';

export const Teaser = ({ inventory }) => {
  if (!inventory) {
    return null;
  }

  const _inventory_keys = Object.keys(inventory);

  return (
    <section className="ids-doc if block section">
      <DocumentationContainer>
        <div className="ids-doc component-status-teaser">
          {_inventory_keys &&
            _inventory_keys.map((scope, index) => {
              const _current_inventory = inventory[scope];
              const { framework, version, entries } = _current_inventory;
              const _number_of_components = entries.length;
              const _label = SCOPE_LABELS[framework];

              return (
                <div
                  key={`scope-${index}`}
                  className="if visualization infographic box highlight ids-doc component-status-teaser-box">
                  <span className="if text">
                    <span className="if number">{_number_of_components}</span>
                  </span>
                  <span className="if ids-doc description">
                    {_label}
                    <span className="if ids-doc tag mint">{version}</span>
                  </span>
                </div>
              );
            })}
        </div>
      </DocumentationContainer>
    </section>
  );
};

Teaser.propTypes = {
  inventory: PropTypes.shape({})
};
