import React, { useEffect, useState, useCallback, useRef } from 'react';
import cx from 'classnames';

import { handleClick } from './lib';

export const BackToTop = () => {
  const useIsMounted = () => {
    const _is_mounted_ref = useRef(true);

    useEffect(() => {
      return () => {
        _is_mounted_ref.current = false;
      };
    }, []);

    return () => _is_mounted_ref.current;
  };
  const useMountedState = defaultValue => {
    const _is_mounted = useIsMounted();
    const [state, setState] = useState(defaultValue);
    const defensiveSetState = useCallback(newValue => {
      if (_is_mounted()) {
        setState(newValue);
      }
    }, []);

    return [state, defensiveSetState, _is_mounted];
  };
  const [_page_height, _set_page_height] = useState(0);
  const [_is_visible, _set_is_visible] = useState(false);
  const [_is_deactivating, _set_is_deactivating, _is_mounted] = useMountedState(false);
  const _on_scroll = function _on_scroll() {
    window.requestAnimationFrame(function _raf_on_scroll() {
      if (window.pageYOffset >= _page_height && !_is_deactivating) {
        _set_is_visible(true);
      } else if (
        (window.innerHeight + window.scrollY >= document.body.offsetHeight ||
          window.pageYOffset === 0 ||
          window.pageYOffset < _page_height) &&
        !_is_deactivating
      ) {
        if (_is_visible) {
          _set_is_visible(false);
          _set_is_deactivating(true);
          setTimeout(() => {
            if (_is_mounted()) {
              _set_is_deactivating(false);
            }
          }, 2000);
        }
      }
    });
  };
  const _on_resize = function _on_resize() {
    const _raf_on_resize = function _raf_on_resize() {
      _set_page_height(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0));
    };

    window.requestAnimationFrame(_raf_on_resize);
    _on_scroll();
  };
  const _class_names = cx('if floating-action-button back-to-top', {
    'is-deactivating': _is_deactivating,
    'is-visible': _is_visible
  });

  useEffect(() => {
    _on_resize();
    window.addEventListener('resize', _on_resize);

    return () => {
      window.removeEventListener('resize', _on_resize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', _on_scroll);

    return () => {
      window.removeEventListener('scroll', _on_scroll);
    };
  }, [_is_visible, _is_deactivating, _page_height]);

  return (
    <button
      aria-label="Back to top"
      type="button"
      onKeyPress={e => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
      onClick={handleClick}
      className={_class_names}>
      Back to top
    </button>
  );
};
