import React from 'react';
import PropTypes from 'prop-types';

import { Indicator } from './';

import { getComponentStatus, getJSStatus, getReactComponentStatus, getWcComponentStatus } from './lib';

export const InventoryCellNoPackage = ({ scope, component }) => {
  if (scope === '@ids-js') {
    return (
      <td className="if" scope="col">
        <Indicator status={getJSStatus(component, false, 'Not available')} />
      </td>
    );
  } else if (scope === '@ids-wc') {
    return (
      <td className="if" scope="col">
        <Indicator status={getWcComponentStatus(component, false, 'Not available')} />
      </td>
    );
  } else if (scope === '@ids-core') {
    return (
      <td className="if" scope="col">
        <Indicator status={getComponentStatus(component, false, 'Not Available')} />
      </td>
    );
  } else if (scope === '@ids-relax-core') {
    return (
      <td className="if" scope="col">
        <Indicator status={getComponentStatus(component, false, 'Not Available')} />
      </td>
    );
  } else if (scope === '@ids-react') {
    return (
      <td className="if" scope="col">
        <Indicator status={getReactComponentStatus(component, false, 'Not available')} />
      </td>
    );
  } else {
    return null;
  }
};

InventoryCellNoPackage.propTypes = {
  scope: PropTypes.string,
  component: PropTypes.string
};
