export const getJSStatus = (packageName, hasLib, value) => {
  if (
    [
      'core',
      'bundle',
      'breakpoint',
      'grid',
      'avatar',
      'consent-banner',
      'blockquote',
      'checkbox',
      'radio',
      'toggle',
      'dropdown-select',
      'global-footer',
      'header',
      'link',
      'link-list',
      'pagination',
      'quick-facts',
      'shortcuts',
      'split',
      'status-indicator',
      'textarea',
      'typography',
      'icon'
    ].includes(packageName)
  ) {
    return 'Not doing';
  }

  if (hasLib) return 'Available';

  return value;
};

export const getReactComponentStatus = (packageName, hasComponent, value) => {
  if (['core', 'utils', 'card', 'bundle', 'breakpoint'].includes(packageName)) {
    return 'Not doing';
  }

  if (hasComponent) return 'Available';

  return value;
};

export const getWcComponentStatus = (packageName, hasComponent, value) => {
  if (['core', 'utils', 'card', 'bundle', 'breakpoint', 'icon', 'consent-banner', 'grid'].includes(packageName)) {
    return 'Not doing';
  }

  if (hasComponent) return 'Available';

  return value;
};

export const getDocPath = packageName => {
  if (['button', 'contextual-menu', 'floating-action-button', 'icon-button'].includes(packageName)) {
    return '/components/actions';
  } else if (['disclosure-card', 'editorial-card', 'card', 'info-card', 'navigational-card'].includes(packageName)) {
    return '/components/cards';
  } else if (
    ['alert-banner', 'consent-banner', 'loader', 'progress-tracker', 'status-indicator', 'toast'].includes(packageName)
  ) {
    return '/components/feedback';
  } else if (
    [
      'autocomplete',
      'checkbox',
      'datepicker',
      'dropdown-filter',
      'dropdown-select',
      'file-upload',
      'input-fields',
      'input-label',
      'numeric-stepper',
      'phonenumber',
      'radio-buttons',
      'search-field',
      'segmented-control',
      'slider',
      'textarea',
      'toggle'
    ].includes(packageName)
  ) {
    return '/components/inputs';
  } else if (['breakpoint', 'grid', 'section'].includes(packageName)) {
    return '/components/layout';
  } else if (['avatar', 'icons', 'logo', 'video'].includes(packageName)) {
    return '/components/media';
  } else if (
    [
      'accordion-menu',
      'breadcrumbs',
      'dropdown-menu',
      'link',
      'link-list',
      'pagination',
      'shortcuts',
      'sidebar-menu',
      'tabs',
      'tooltip-menu'
    ].includes(packageName)
  ) {
    return '/components/navigation';
  } else if (['help-tooltip', 'info-tooltip', 'modal', 'dialog', 'popover', 'tooltip'].includes(packageName)) {
    return '/components/overlay';
  } else if (
    [
      'banner',
      'faq',
      'global-footer',
      'global-header',
      'global-minimal-header',
      'header',
      'hero',
      'hero-navigation',
      'panel',
      'quick-facts',
      'split'
    ].includes(packageName)
  ) {
    return '/components/page-sections';
  } else if (['data-tables', 'product-matrix-table', 'comparison-table'].includes(packageName)) {
    return '/components/tables';
  } else if (['blockquote', 'tag', 'typography'].includes(packageName)) {
    return '/components/text';
  }

  return '/components';
};

export const getComponentStatus = (packageName, hasComponent, value) => {
  if (['core', 'bundle', 'utils', 'breakpoint', 'grid', 'card', 'common', 'helpers'].includes(packageName)) {
    return 'Not doing';
  }

  if (hasComponent) return 'Available';

  return value;
};

export const humanize = string => {
  if (!string) return '';

  if (typeof string !== 'string') return string;

  if (string.length === 0) return string;

  return string
    .trim()
    .replace(/ +/g, ' ')
    .replace(/-/g, ' ')
    .split(' ')
    .map(string => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ');
};

export const setComponents = inventory => {
  const _inventory_keys = Object.keys(inventory).sort();
  const _components = {};

  _inventory_keys.forEach(scope => {
    const _current_inventory = inventory[scope];
    const { entries } = _current_inventory;

    entries.forEach(component => {
      if (_components[component]) {
        _components[component].frameworks = [..._components[component].frameworks, `${scope}/${component}`];
      } else {
        _components[component] = {
          docs: getDocPath(component),
          componentName: component,
          frameworks: [`${scope}/${component}`]
        };
      }
    });
  });

  return _components;
};
