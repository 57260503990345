import React, { useEffect, useState } from 'react';

import { Layout } from '../../../components/Layout';
import { SEO } from '../../../components/SEO';
import { BackToTop } from '../../../components/BackToTop';
import { Header, Teaser, Components } from '../../../components/ComponentStatus';

import '../../../components/ComponentStatus/styles/component-status.styl';

const IconsPage = () => {
  const [_inventory, _set_inventory] = useState({});

  useEffect(() => {
    const _fetch = async () => {
      const _inventory_response = await fetch(
        'https://ifdesignsystemstorage.blob.core.windows.net/inventory/inventory.json'
      );
      const _inventory_json = await _inventory_response.json();

      _set_inventory(_inventory_json);
    };

    _fetch();
  }, []);

  return (
    <Layout header={true}>
      <SEO />
      <Header />
      <Teaser inventory={_inventory} />
      <Components inventory={_inventory} />
      <BackToTop />
    </Layout>
  );
};

export default IconsPage;
