import React from 'react';

import { TextLayoutLeft, DocumentationContainer } from '../Layout';

import './styles/header.styl';

export const Header = () => {
  return (
    <section className="ids-doc if block section" id="content">
      <DocumentationContainer>
        <TextLayoutLeft>
          <h1 className="ids-doc sg if heading largest">Component Status</h1>
          <p className="if text lead">
            Components and their variants have statuses reflecting their state of completion, documented on this page.
            Check back here anytime to see current component status information.
          </p>
          <p className="ids-doc if text meta disclaimer">
            See more detailed progress on our{' '}
            <a
              href="https://dev.azure.com/if-it/If%20Design%20Hub/_boards/board/t/If%20Design%20Hub%20Team/Stories"
              className="if"
              target="_blank"
              rel="noopener noreferrer">
              Design System issue tracker
            </a>
            .
          </p>
        </TextLayoutLeft>
      </DocumentationContainer>
    </section>
  );
};
