import React from 'react';
import PropTypes from 'prop-types';

export const InventoryCell = ({ pkg }) => {
  return (
    <td className="if" scope="col">
      <code className="if language-" style={{ fontSize: '14px', lineHeight: '18px' }}>
        {pkg}
      </code>
    </td>
  );
};

InventoryCell.propTypes = {
  pkg: PropTypes.string
};
