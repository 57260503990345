import React from 'react';
import PropTypes from 'prop-types';

import { InventoryCellNoPackage, InventoryCell } from './';

export const InventoryCells = ({ inventory, frameworks, component }) => {
  const _inventory_keys = Object.keys(inventory).sort();

  return (
    <>
      {_inventory_keys.map((scope, index) => {
        const _filtered_packages = frameworks.filter(f => f === `${scope}/${component}`);
        const _package = _filtered_packages.length ? _filtered_packages : null;

        if (_package === null) {
          return <InventoryCellNoPackage key={`cell-${index}`} component={component} scope={scope} />;
        }

        return <InventoryCell key={`cell-${index}`} pkg={_package[0]} />;
      })}
    </>
  );
};

InventoryCells.propTypes = {
  inventory: PropTypes.shape({}),
  frameworks: PropTypes.array,
  component: PropTypes.string
};
