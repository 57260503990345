import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { DocumentationContainer } from '../Layout';
import { SCOPE_LABELS, humanize, setComponents } from './lib';

import { InventoryCells } from './';

import './styles/components.styl';

export const Components = ({ inventory }) => {
  if (!inventory) {
    return null;
  }

  const _inventory_keys = Object.keys(inventory).sort();
  const _components = setComponents(inventory);
  const _component_keys = Object.keys(_components).sort();

  return (
    <section className="ids-doc if block section">
      <DocumentationContainer>
        <div className="if table-container responsive xl">
          <table className="ids-doc if table responsive-scroll condensed">
            <thead className="if filled">
              <tr className="if">
                <th className="if" scope="col" style={{ width: '13rem' }}>
                  Name
                </th>
                {_inventory_keys &&
                  _inventory_keys.map((scope, index) => {
                    const _current_inventory = inventory[scope];
                    const { framework } = _current_inventory;
                    const _label = SCOPE_LABELS[framework];

                    return (
                      <th key={`component-status-header-${index}`} className="if" scope="col">
                        {_label}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="if">
              {_component_keys &&
                _component_keys.map((component, index) => {
                  const _current_component = _components[component];
                  const { frameworks } = _current_component;

                  return (
                    <tr className="if" key={`row-${index}`}>
                      <td className="if">
                        <Link to={_current_component.docs} className="if">
                          {humanize(component)}
                        </Link>
                      </td>
                      <InventoryCells inventory={inventory} frameworks={frameworks} component={component} />
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </DocumentationContainer>
    </section>
  );
};

Components.propTypes = {
  inventory: PropTypes.shape({})
};
