import { srSpeak } from '../../../lib';

export const handleClick = () => {
  const _anchor = document.getElementById('content');

  if (_anchor) {
    _anchor.scrollIntoView();

    const _first_focusable_el = _anchor.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )[0];

    _first_focusable_el.focus();
  } else {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  srSpeak('Scrolled to top');
};

export const init = () => {
  let _last_position = -1;
  let _is_deactivating = false;

  const _page_height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const _back_to_top_el = document.querySelector('.if.js-back-to-top');
  const _handle_scroll = () => {
    window.requestAnimationFrame(function () {
      // Avoid calculations if not needed
      if (_last_position == window.pageYOffset) {
        return false;
      } else if (window.pageYOffset >= _page_height && !_is_deactivating) {
        _last_position = window.pageYOffset;
        _back_to_top_el.classList.add('is-visible');

        return false;
      } else if (_is_deactivating) {
        _last_position = window.pageYOffset;

        return false;
      } else if (window.pageYOffset < _page_height && !_is_deactivating) {
        _last_position = window.pageYOffset;

        if (_back_to_top_el.classList.contains('is-visible')) {
          _is_deactivating = true;
          _back_to_top_el.classList.remove('is-visible');
          _back_to_top_el.classList.add('is-deactivating');
          setTimeout(() => {
            _back_to_top_el.classList.remove('is-deactivating');

            _is_deactivating = false;
          }, 2000);
        }

        return false;
      } else {
        _last_position = window.pageYOffset;
      }
    });
  };

  document.removeEventListener('scroll', _handle_scroll);
  document.addEventListener('scroll', _handle_scroll);
};

export default init;
