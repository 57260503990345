import React from 'react';
import PropTypes from 'prop-types';

export const Indicator = ({ status }) => {
  const _status = status === null ? 'Not available' : status;

  if (_status === 'Not available') {
    return (
      <span className="if status-indicator paused" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  } else if (_status === 'Released') {
    return (
      <span className="if status-indicator active" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  } else if (_status === 'Available') {
    return (
      <span className="if status-indicator active" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  } else if (_status === 'Not doing') {
    return (
      <span className="if status-indicator error" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  } else if (_status === 'Upcoming') {
    return (
      <span className="if status-indicator success" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  } else {
    return (
      <span className="if status-indicator paused" style={{ whiteSpace: 'nowrap' }}>
        <span className="if text meta u-hidden-down--lg">{_status}</span>
      </span>
    );
  }
};

Indicator.propTypes = {
  status: PropTypes.string
};
